export enum EnumOrderType{
  电子版订单=1,
  纸质版订单=2,
  面料订单=3,
  饰品订单=4,
  量体订单=5,
  定制版型订单=6,
  量体定制订单=7,
  成衣定制订单=8,
  成衣现货订单=9,
  辅料订单=10,
  成衣预售订单=11,
  视频教学订单=12,
}

export enum EnumOrderStatus{
  None=-1,
  已提交=0,
  已确认=1,
  已支付=2,
  已发货=3,
  已退款=4,
  已作废=5,
  已打印=6,
  售后中=7,
  售后已处理=8,
  已收货=9,
  已安装=10
}
export enum LogisticProvider{
  极兔=15,
  顺丰快递=1,
  中通快递=2,
  圆通快递=3,
  申通快递=4,
  韵达快递=5,
  天天=6,
  京东=7,
  德邦=8,
  宅急送=9,
  百世汇通=10,
  中邮物流=11,
  安捷快递=13,
  安信达=12,
 EMS=14,
 其它=16
}